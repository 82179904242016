<template>
    <div class="container">
        
        <div style="width: 1000px; margin: 80px auto">
            
            <el-form 
                ref="form" 
                :model="form" 
                :rules="rules"
                label-width="130px" 
                :inline="true" 
            >
                <el-form-item label="省份:" prop="province">
                    <el-input v-model="form.province" style="width: 300px;" ></el-input>
                </el-form-item>
                <el-form-item label="学校名称:"  prop="schoolName">
                    <el-input v-model="form.schoolName" style="width: 300px;" ></el-input>
                </el-form-item>
                <el-form-item label="联系地址:" prop="address">
                    <el-input v-model="form.address" style="width: 700px;" ></el-input>
                </el-form-item>
                <el-form-item label="指导老师1:" prop="teacher1">
                    <el-input v-model="form.teacher1" style="width: 300px;" ></el-input>
                </el-form-item>
                <el-form-item label="联系方式:" prop="teacher1Contact">
                    <el-input v-model="form.teacher1Contact" style="width: 300px;" ></el-input>
                </el-form-item>
                <el-form-item label="指导老师2:">
                    <el-input v-model="form.teacher2" style="width: 300px;" ></el-input>
                </el-form-item>
                <el-form-item label="联系方式:" >
                    <el-input v-model="form.teacher2Contact" style="width: 300px;" ></el-input>
                </el-form-item>
                <el-form-item label="学生姓名:" prop="studentName">
                    <el-input v-model="form.studentName" style="width: 700px;" placeholder="2~6名学生姓名,请使用“，”隔开" ></el-input>
                </el-form-item>
                <el-form-item label="上传报名表:" prop="applyUrl">
                    <el-upload
                        class="avatar-uploader"
                        :action="action"
                        multiple
                        list-type="picture"
                        :limit="1"
                        :before-upload="handleBefore"
                        :on-success="hanleSuccess"
                        :show-file-list="false"
                        
                    >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <div style="margin-bottom: 20px; margin-left: 100px;">
                    <a href="https://kxwj.cn/observer/files/活动申报表.pdf">活动申报表下载</a>
                </div>
                <el-form-item label="备注:">
                    <el-input type="textarea" v-model="form.remark" style="width: 700px;" :maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-form>

            <div style="text-align: center; margin-top: 20px;">
                <el-button type="primary" style="width: 400px;" @click="submit">提交</el-button>
                
            </div>
            <div style="text-align: center; margin-top: 20px;"  v-if="disabled">
                恭喜!您已报名成功!!!
            </div>
        </div>
    </div>
</template>

<script >
import { $activity } from "@/services";
import $cookies from "vue-cookies";
import {BASE} from '@/services/base'
export default {
    data() {
        return {
            form:{},
            fileList:[],
            action: BASE + '/server/common/upload',
            rules:{
                'province': [
                    { required: true, message: '请填写学校所在省份', trigger: 'blur' },
                ],
                'schoolName': [
                    { required: true, message: '请填写学校完整名称', trigger: 'blur' },
                ],
                'teacher1': [
                    { required: true, message: '请填写指导老师1', trigger: 'blur' },
                ],
                teacher1Contact:[
                    { required: true, message: '请填写指导老师1的联系方式', trigger: 'blur' },
                ],
                'address': [
                    { required: false, message: '请填写学校详细地址', trigger: 'blur' },
                ],
                'studentName': [
                    { required: true, message: '请填写学生姓名(2-6名),用“，”隔开', trigger: 'blur' },
                ],
                'applyUrl': [
                    { required: true, message: '请上传报名表扫描件', trigger: 'blur' },
                ],
            },
            disabled:false,
            imageUrl:''
        }
    },
    created() {
        this.form.userId = $cookies.get("userInfo").id
        this.getInfo()
    },
    methods:{
        getInfo() {
            $activity.getSignDetail($cookies.get("userInfo").id).then(res => {
                if (res.code === 200) {
                    if (res.data.id) {
                        this.form = res.data
                        this.imageUrl = res.data.applyUrl
                        this.disabled = true
                    }
                }
            }) 
        },
        handleBefore(file) {
            if (file.size / 1024 / 1024 > 5) {
                this.$message.error('上传文件不能大于5M')
                return
            }
            this.imageUrl = URL.createObjectURL(file);
        },
        hanleSuccess(response) {
            if(response.code === 200) {
                this.form.applyUrl = response.data.url
                console.log(this.form)
            }
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    $activity.setSign(this.form).then(res => {
                        if (res.code === 200) {
                            this.$message.success('报名成功')
                            this.getInfo()
                        }
                    })
                    // if (this.form.teacher1 || this.form.teacher2) {
                    //     if (this.form.teacher1 && !this.form.teacher1Contact) {
                    //         this.$message.error('请填写指导老师1的联系方式')
                    //         return
                    //     }
                    //     if (this.form.teacher2 && !this.form.teacher2Contact) {
                    //         this.$message.error('请填写指导老师2的联系方式')
                    //         return
                    //     }
                    //     $activity.setSign(this.form).then(res => {
                    //         if (res.code === 200) {
                    //             this.$message.success('报名成功')
                    //             this.getInfo()
                    //         }
                    //     })
                    // } else {
                    //     this.$message.error('请至少填写以为指导老师')
                    // }
                }
            })
        },
    },

}
</script>

<style>
.container {
    padding: 40px;
}
.avatar-uploader{
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-form-item__label {
    font-size: 18px;
    }
    .el-divider__text {
        font-size: 24px;
    }
    .el-input__inner {
        font-size: 18px;
    }
</style>